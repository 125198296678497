.menu {
  display: flex;
  flex-direction: column;
  user-select: none;
  border-radius: 0.5em;
  box-shadow: 0 0 1em #999;
}

.menu_item {
  padding: 1em;
  list-style-type: none;
  color: #999999;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.menu_item:first-child {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.menu_item:last-child {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.menu_item:hover {
  background-color: #999999;
  color: #fff;
}
