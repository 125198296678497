.group_membership_list {
  position: relative;
  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 65px);

}


@media  (max-width: 400px) {
  .group_membership_list {
    position: relative;
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 125px);
    margin-bottom: 60px;
  
  }
}

.membership {
  padding: 0.5em;
}

.membership > div {
  display: flex;
  flex-direction: row;
}

.membership > div > a {
  flex: 1;
}

.info span {
  font-size: 0.8em;
}

.info span + span {
  margin-left: 0.25em;
  margin-right: 0.25em;
}
